body {
  overflow: hidden;
  margin: 0; 
}

.App { 
  height: 100vh;
  overflow: hidden; 
} 

/* .toolbar {
  width: calc(100vw - 30px);
}
  */
.toolbar-button {
  color: white;
  font-size: 0.75rem;
  margin-right: 12px;
  font-weight: 900;
}

.toolbar-button.logo { 
  font-size: 0.9rem; 
  font-weight: 400;
  margin-right: 24px;
  box-shadow: var(--card-shadow);
  padding: 8px;
  border-radius: 4px;
}

.toolbar-button:not(.logo):hover { 
  text-decoration: underline;
  cursor: pointer;
}

.search-box {
  border: solid 1px rgb(95, 125, 170);
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 0.8rem;
  color: white;
  min-width: 160px;
  cursor: pointer;
}

.search-box:hover {
  border: solid 1px rgb(113, 145, 194);
}

.search-box:active {
  border: solid 1px rgb(146, 165, 194);
}

.search-k {
  font-size: 0.6rem;
  border: solid 1px rgb(56, 96, 156);
  margin-left: 22px;
  border-radius: 4px;
  padding: 2px 4px;
}

.link {
  font-family: var(--font-family);
  cursor: pointer;  
}
.link:hover {
  text-decoration: underline;
}
.link:active {
  font-weight: 800;
}

.bold {
  font-weight: 900;
}

.workspace {
  margin-top: 48px;
} 

.workspace .banner {
  width: 40vw;
  padding:  60px;
}
.workspace .preview {
  width: 50vw;
  background-color: var(--back-color-primary);
  border-radius: 0 0 0 8px;
}

.demo-paper {
  background-color: beige;
}

.git-button {
  width: 120px; 
  border-radius: 4px; 
  padding: 10px 12px;
  background-color: var(--back-color-primary);
  color: white;
}

.git-box {
  width: 25vw;
  border: solid 1px rgba(0,0,0,0.2);
  border-radius: 4px;
  padding-right: 12px;
}

.git-url {
  max-width: 286px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 12px;
}